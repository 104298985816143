import { Account, Member, Partner, User } from './types'

export function mapUser(data): User {
  return {
    id: data.id,
    fullName: `${data.first_name} ${data.last_name}`,
    initials: `${data.first_name.charAt(0)}${data.last_name.charAt(0)}`.toUpperCase(),
    avatar: data.avatar,
    avatarColour: data.avatar_colour,
    emailConfirmed: data.confirmed_at !== null,
    invitationCount: data.invitations_count,
    intercomHash: data.intercom_user_hash,
    datetimeConfig: {
      timeFormat: data.time_format,
      dateFormat: data.date_format,
      timeZone: data.timezone,
      relative: data.display_date_relative
    },
    isPapertrailEmployee: data.is_papertrail_employee
  }
}

export function mapAccount(data): Account {
  const isTrial =
    data.subscription && data.subscription.data.package && data.subscription.data.package.data.code == 'base-package'

  return {
    id: data.id,
    name: data.name,
    logo: data.logo,
    isTrial: isTrial,
    isDemo: data.demo,
    expiresAt: data.trial_expires_at ? data.trial_expires_at : undefined,
    rootFolderId: data.folder ? data.folder.data.id : undefined,
    hasTasks: data.flags ? getFlag(data.flags.data, 'tasks') : false,
    hasIAuditor: data.flags ? getFlag(data.flags.data, 'iauditor') : false,
    taskStates: data.task_states ? data.task_states.data : undefined,
    hasChecklistEditor: data.flags ? getFlag(data.flags.data, 'checklist_editor') : false,
    currency: data.currency ? data.currency : undefined
  }
}

function getFlag(flags, key) {
  const flag = flags.find((flag) => flag.key === key)
  return flag ? flag.active : false
}

export function mapMember(data): Member {
  return {
    id: data.id,
    isAdmin: data.admin,
    isViewOnly: data.view_only
  }
}

export function mapPartner(data): Partner {
  return {
    id: data.id,
    partnerName: data.partner_name,
    partnerSummary: data.partner_summary,
    partnerDomain: data.partner_domain,
    images: {
      publicLogo: data.images.public_logo,
      appLogo: data.images.app_logo,
      publicCompany: data.images.public_company
    }
  }
}
